<script setup lang="ts">
import { useTresContext, useRenderLoop } from '@tresjs/core';
import { Image } from '@tresjs/cientos';

import type { Camera, PerspectiveCamera, WebGLRenderer, Scene } from 'three';
import { Color, Vector2, Vector3 } from 'three';

import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
// import { VignetteShader } from 'three/examples/jsm/shaders/VignetteShader.js';

const ctx = useTresContext();

const scene = ctx.scene.value;
const camera = ctx.camera.value;
const renderer = ctx.renderer.value;

const { onLoop } = useRenderLoop();
const { width, height } = useWindowSize();

// Конфигурационные параметры
const CONFIG = {
  // Максимальное количество одновременно отображаемых волн
  MAX_WAVES: 50,

  // Время жизни волны в секундах (влияет на длину шлейфа)
  WAVE_LIFETIME: 0.51,

  // Базовая сила искажения (0.0 - 1.0)
  BASE_AMPLITUDE: 0.01,

  // Максимальная скорость мыши для нормализации (пикселей в секунду)
  MAX_MOUSE_SPEED: 2.0,

  // Коэффициент плавности движения курсора (0.0 - мгновенно, 1.0 - максимальная плавность)
  LERP_FACTOR: 0.2,

  // Частота волн (количество гребней на единицу расстояния)
  FREQUENCY: 15.01,

  // Скорость распространения волн (чем выше - быстрее движутся гребни)
  WAVE_SPEED: 10.01,

  // Коэффициент затухания волн (чем выше - меньше радиус распространения)
  DECAY_FACTOR: 20.01,

  /*
   * TODO: Дополнительные параметры:
   */
  // Размер частиц (если используется система частиц)
  PARTICLE_SIZE: 2.0,
  // Цвет волн в формате HEX (если используется визуализация)
  WAVE_COLOR: 0x00ffff,
  // Минимальная скорость для активации волны (0.0 - 1.0)
  MIN_ACTIVATION_SPEED: 0.01,
};

type WavePoint = {
  position: Vector2;
  time: number;
  velocity: number;
};

// const initScene = () => {
//   const scene = new Scene();
//   const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
//   const renderer = new WebGLRenderer({ antialias: true });

//   renderer.setSize(window.innerWidth, window.innerHeight);
//   document.body.appendChild(renderer.domElement);

//   return { scene, camera, renderer };
// };

const createWaveEffect = (
  renderer: WebGLRenderer,
  scene: Scene,
  camera: Camera,
) => {
  const composer = new EffectComposer(renderer);
  composer.addPass(new RenderPass(scene, camera));

  const wavePass = new ShaderPass({
    uniforms: {
      tDiffuse: { value: null },
      uTime: { value: 0 },
      uAspect: { value: window.innerWidth / window.innerHeight },
      uWavePoints: { value: new Float32Array(CONFIG.MAX_WAVES * 4) },
      uWaveCount: { value: 0 },
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D tDiffuse;
      uniform float uTime;
      uniform float uAspect;
      uniform float uWavePoints[${CONFIG.MAX_WAVES * 4}];
      uniform int uWaveCount;
      varying vec2 vUv;

      vec4 getWavePoint(int index) {
          int base = index * 4;
          return vec4(
              uWavePoints[base],
              uWavePoints[base + 1],
              uWavePoints[base + 2],
              uWavePoints[base + 3]
          );
      }

      void main() {
          vec2 uv = vUv;
          vec2 totalDistortion = vec2(0.0);
          float currentTime = uTime;

          for(int i = 0; i < ${CONFIG.MAX_WAVES}; i++) {
              if(i >= uWaveCount) break;

              vec4 wave = getWavePoint(i);
              vec2 wavePos = wave.xy;
              float waveTime = wave.z;
              float velocity = wave.w;

              float timeDelta = currentTime - waveTime;
              float life = 1.0 - clamp(timeDelta / ${CONFIG.WAVE_LIFETIME}, 0.0, 1.0);

              if(life <= 0.0) continue;

              vec2 toWave = (uv - wavePos) * vec2(uAspect, 1.0);
              float distance = max(length(toWave), 0.001);

              float amplitude = ${CONFIG.BASE_AMPLITUDE} * velocity;
              float waveFactor = sin(distance * ${CONFIG.FREQUENCY} - timeDelta * ${CONFIG.WAVE_SPEED});

              vec2 distortion = vec2(
                  waveFactor,
                  cos(waveFactor)
              ) * amplitude * life / (distance * ${CONFIG.DECAY_FACTOR} + 1.0);

              totalDistortion += distortion;
          }

          uv += totalDistortion;
          uv = clamp(uv, 0.0, 1.0);

          gl_FragColor = texture2D(tDiffuse, uv);
      }
    `,
  });

  composer.addPass(wavePass);

  // Vignette pass
  // const vignetteShaderPass = new ShaderPass(VignetteShader);
  // composer.addPass(vignetteShaderPass);

  return composer;
};

if (!camera) throw new Error('camera is required');

// const { scene, camera, renderer } = initScene();
const composer = createWaveEffect(renderer, scene, camera);

let wavePoints: WavePoint[] = [];
const lastMousePos = new Vector2(0.5, 0.5);
const targetMousePos = new Vector2(0.5, 0.5);
const currentMousePos = new Vector2(0.5, 0.5);
let lastMouseTime = performance.now();

// Обработчики событий
document.addEventListener('mousemove', (e: MouseEvent) => {
  targetMousePos.set(
    e.clientX / window.innerWidth,
    1 - e.clientY / window.innerHeight,
  );
});

// Обработка touch-событий
document.addEventListener('touchmove', (e: TouchEvent) => {
  const touch = e.touches[0];
  if (!touch) return;

  targetMousePos.set(
    touch.clientX / window.innerWidth,
    1 - touch.clientY / window.innerHeight,
  );
});

// window.addEventListener('resize', () => {
//   const width = window.innerWidth;
//   const height = window.innerHeight;

//   camera.aspect = width / height;
//   camera.updateProjectionMatrix();
//   renderer.setSize(width, height);
//   composer.setSize(width, height);
// });

// Анимационный цикл
const tick = () => {
  // Интерполяция позиции
  currentMousePos.lerp(targetMousePos, CONFIG.LERP_FACTOR);

  // Расчет скорости
  const now = performance.now();
  const deltaTime = (now - lastMouseTime) / 1000;
  const distance = currentMousePos.distanceTo(lastMousePos);
  const velocity =
    Math.min(distance / deltaTime, CONFIG.MAX_MOUSE_SPEED) /
    CONFIG.MAX_MOUSE_SPEED;

  // Добавление новой волны
  if (velocity > 0.01) {
    wavePoints.push({
      position: currentMousePos.clone(),
      time: now / 1000,
      velocity: velocity,
    });

    // DSMax Ограничение истории волн
    // if (wavePoints.length > CONFIG.MAX_WAVES) {
    // wavePoints = wavePoints.slice(-CONFIG.MAX_WAVES * 2);
    // }

    // QWEN ограничить массив только активными волнами
    wavePoints = wavePoints.filter(
      (w) => now / 1000 - w.time < CONFIG.WAVE_LIFETIME,
    );

    lastMousePos.copy(currentMousePos);
    lastMouseTime = now;
  }

  // Обновление шейдера
  const wavePass = composer.passes[1] as ShaderPass;
  // const validWaves = wavePoints
  //   .filter((w) => now / 1000 - w.time < CONFIG.WAVE_LIFETIME)
  //   .flatMap((w) => [w.position.x, w.position.y, w.time, w.velocity]);

  // const validWaves = wavePoints
  //   .filter((w) => {
  //     const age = now / 1000 - w.time;
  //     return age >= 0 && age < CONFIG.WAVE_LIFETIME; // Добавлена проверка age >= 0
  //   })
  //   .flatMap((w) => [w.position.x, w.position.y, w.time, w.velocity]);

  const nowInSeconds = performance.now() / 1000;
  const validWaves = wavePoints
    .filter((w) => nowInSeconds - w.time < CONFIG.WAVE_LIFETIME)
    .flatMap((w) => [w.position.x, w.position.y, w.time, w.velocity]);

  const waveData = new Float32Array(CONFIG.MAX_WAVES * 4);
  waveData.set(validWaves.slice(0, CONFIG.MAX_WAVES * 4)); // Жёсткое ограничение длины

  wavePass.uniforms.uWavePoints!.value = waveData;
  wavePass.uniforms.uWaveCount!.value = Math.floor(validWaves.length / 4);
  wavePass.uniforms.uTime!.value = now / 1000;
  wavePass.uniforms.uAspect!.value = window.innerWidth / window.innerHeight;

  composer.render();

  // console.log('Active waves:', wavePoints.length);
  // console.log('validWaves: ', validWaves.length);
};

onLoop(({ elapsed, delta }) => {
  tick();
});

// defineExpose({
//   tick,
// });

// onMounted(() => {
//   initApp();
// });
</script>

<template lang="pug">
TresGroup
</template>
